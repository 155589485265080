<template>
  <div style="position: relative" @click="selectSeat">
    <div
      :class="seat_number < 10 ? 'success22' : 'success2'"
      style="position: absolute; top: 32px; font-size: 14px"
    >
      <spanm
        style="color: #6d6d6d; font-size: 14px; font-weight: 300; cursor: pointer"
        >{{ seat_number }}</spanm
      >
    </div>
    <svg
      style="display: flex"
      width="67"
      height="97"
      viewBox="0 0 33 95"
      fill="#0EB8EE"
      xmlns="http://www.w3.org/2000/svg"
      class="cursor-pointer"
    >
      <g clip-path="url(#clip0_7_78)">
        <path
          v-if="!isDisable(ticket)"
          fill="#50d1fa"
          d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z"
        />
        <path
          v-if="hasSelected(ticket)"
          fill="#fafa75"
          d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z"
        />
        <path
          v-if="!hasSelected(ticket) && seats.length > 3"
          fill="white"
          d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z"
        />
        <path
          d="M26.7456 94C27.9096 94 29.0056 93.542 29.8276 92.7112C30.6476 91.8779 31.1015 90.7661 31.1015 89.5852V6.3953H27.7146C27.7146 8.5588 25.9766 10.3196 23.8416 10.3196H8.35855C7.32755 10.3196 6.35456 9.9099 5.62256 9.1687C4.89056 8.4253 4.48756 7.4402 4.48756 6.3953H1.10156V89.5852C1.10156 90.7686 1.55256 91.8779 2.37456 92.7112C3.19456 93.542 4.28856 94 5.45456 94H26.7456ZM23.8416 9.8269C25.7186 9.8269 27.2286 8.2964 27.2286 6.3931C27.2286 4.4917 25.7186 2.95874 23.8416 2.95874H8.35855C6.47955 2.95874 4.97256 4.4917 4.97256 6.3931C4.97256 8.2964 6.47955 9.8269 8.35855 9.8269H23.8416ZM30.6145 5.9048C30.8825 5.9048 31.1015 5.6841 31.1015 5.4148C31.1015 4.2341 30.6476 3.12451 29.8236 2.2915C29.0016 1.45825 27.9056 1 26.7456 1H5.45456C3.05456 1 1.10156 2.97949 1.10156 5.4148C1.10156 5.6841 1.31956 5.9048 1.58556 5.9048H4.51556H4.51756C4.75556 3.94629 6.40955 2.47095 8.35855 2.47095H23.8416C25.7926 2.47095 27.4446 3.94629 27.6856 5.9048H30.6145ZM26.7456 95H5.45456C4.01956 95 2.67356 94.437 1.66356 93.4138C0.65556 92.3931 0.101562 91.0334 0.101562 89.5852V6.3953V5.3953C0.110562 2.41846 2.50956 0 5.45456 0H26.7456C28.1796 0 29.5246 0.56445 30.5346 1.58911C31.5406 2.60449 32.0955 3.95581 32.1015 5.3953V5.4148V6.3953V89.5852C32.1015 91.031 31.5465 92.3904 30.5405 93.4128C29.5265 94.437 28.1796 95 26.7456 95Z"
          fill="#8090A0"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_78">
          <rect width="33" height="95" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
<script>
import { doOrderApi } from "@/services/railway_do_order";
export default {
  name: "Cv_ticket",
  props: {
    placess: Array,
    ticket: String,
    arr: Array,
    seat_number: Number,
    disable: Boolean,
    indexOf: Number,
    disables: Boolean,
    seats: Array,
  },
  data() {
    return {
      yellow: "yellow",
      blue: "#0EB8EE",
      disables1: "white",
      bg_color: "",
    };
  },
  setup() {
    const { passengerss } = doOrderApi;
    return {
      passengerss,
    };
  },
  methods: {
    backround() {
      if (this.hasSelected(this.ticket)) return this.blue;
    },
    hasSelected(ticket) {
      return this.seats && this.seats.includes(ticket);
    },
    hasSeat(ticket) {
      return this.placess.includes(ticket);
    },
    isDisable(ticket) {
      if (this.seats && this.seats.length > 0) {
        if (this.placess.includes(ticket)) {
          let ticketIndex = this.placess.findIndex((it) => it === ticket);
          let isLeft = false;
          let isRight = false;
          let isCenter = false;
          for (let i = 0; i < this.seats.length; i++) {
            let seatIndex = this.placess.findIndex(
              (it) => it === this.seats[i]
            );
            if (seatIndex !== -1 && seatIndex - 1 === ticketIndex && !isLeft) {
              isLeft = true;
            }
            if (seatIndex !== -1 && seatIndex + 1 === ticketIndex && !isRight) {
              isRight = true;
            }
            if (
              seatIndex !== -1 &&
              seatIndex + 1 === ticketIndex &&
              isRight &&
              isRight
            ) {
              isCenter = true;
            }
          }
          if (isLeft) {
            return false;
          }
          if (isRight) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      } else {
        return !this.placess.includes(ticket);
      }
    },
    selectSeat() {
      let isSeatSelected = false;
      for (let seat of this.seats) {
        isSeatSelected = seat === this.ticket;
      }
      if (
        !this.placess.includes(this.ticket) ||
        (this.isDisable(this.ticket) && !isSeatSelected)
      ) {
        return;
      }
      if (this.seats.length > 2) {
        let seatIndex = this.seats.indexOf(this.ticket);
        if (
          seatIndex !== -1 &&
          seatIndex !== 0 &&
          seatIndex !== this.seats.length - 1
        ) {
          return;
        }
      }
      this.$emit("selectBilet", this.placess.indexOf(this.ticket), this.ticket);
    },
  },
  mounted() {
    if (this.seats.length > 0)
      for (let i = 0; i < this.seats.length; i++) this.seats.pop();
  },
};
</script>

<style scoped>
.seat_have {
  background-color: #0eb8ee;
}
.cv_style {
  width: 37px;
  height: 19px;
}
.cv_style_h {
  width: 37px;
  height: 108px;
}
.bg-chair {
  background-color: #dbe6f3;
  cursor: pointer;
}
.success22 {
  left: 27px;
}
.success2 {
  left: 24px;
}
.success222 {
  background-color: #dbe6f3;
  color: white;
}
.topk {
  height: 8px;
  width: 18px;
  background-color: #e4effc;
}
.txc {
  color: #fafa75;
}
.left13 {
  left: 15px;
}
</style>
