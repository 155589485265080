<template>
  <div style="position: relative" @click="selectSeat">
    <div
      style="position: absolute; left: 10px; top: 8px; font-size: 14px; z-index: 99"
    >
      <spanm style="color: #6d6d6d; cursor: pointer">{{ seat_number }}</spanm>
    </div>
    <!--    <svg width="38" height="34" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--      <path d="M25.394 33.5H6.45102C5.52102 33.5 4.76501 32.748 4.76501 31.824V30.678H4.31799C2.21299 30.678 0.5 28.976 0.5 26.885V7.118C0.5 5.026 2.21299 3.32401 4.31799 3.32401H4.76501V2.17599C4.76501 1.25199 5.52102 0.5 6.45102 0.5H25.394C27.629 0.5 29.447 2.30701 29.447 4.52901C29.447 4.56501 29.445 4.6 29.443 4.636L29.597 4.746C30.402 5.323 31.076 6.03601 31.599 6.86501L31.74 7.09C34.933 7.191 37.5 9.80301 37.5 13.001V20.999C37.5 24.197 34.933 26.809 31.742 26.91L31.597 27.136C31.068 27.972 30.394 28.685 29.597 29.254L29.443 29.364C29.445 29.399 29.447 29.435 29.447 29.471C29.447 31.693 27.629 33.5 25.394 33.5Z" fill="white"/>-->
    <!--      <path d="M25.394 33C27.353 33 28.947 31.42 28.947 29.471C28.947 28.821 28.42 28.295 27.765 28.295H6.45102C5.79702 28.295 5.26501 28.821 5.26501 29.471V31.824C5.26501 32.474 5.79702 33 6.45102 33H25.394ZM4.789 30.178V29.439C4.789 28.549 5.51902 27.826 6.41602 27.826H27.795C28.468 27.826 29.062 28.227 29.306 28.847C30.056 28.312 30.683 27.645 31.176 26.868C30.174 26.741 29.418 25.889 29.418 24.885V9.118C29.418 8.111 30.174 7.259 31.176 7.132C30.685 6.355 30.056 5.69 29.306 5.153C29.062 5.775 28.468 6.177 27.795 6.177H6.41602C5.51902 6.177 4.789 5.451 4.789 4.564V3.82401H4.31799C2.48999 3.82401 1 5.301 1 7.118V26.885C1 28.699 2.48999 30.178 4.31799 30.178H4.789ZM31.551 26.413C34.56 26.413 37 23.99 37 20.999V13.001C37 10.01 34.56 7.58701 31.551 7.58701C30.635 7.58701 29.892 8.325 29.892 9.235V24.765C29.892 25.676 30.635 26.413 31.551 26.413ZM27.765 5.705C28.42 5.705 28.947 5.17901 28.947 4.52901C28.947 2.58001 27.353 1 25.394 1H6.45102C5.79702 1 5.26501 1.52599 5.26501 2.17599V4.52901C5.26501 5.17901 5.79702 5.705 6.45102 5.705H27.765ZM25.394 34H6.45102C5.24602 34 4.26501 33.024 4.26501 31.824V31.178C1.90801 31.15 0 29.235 0 26.885V7.118C0 4.768 1.90801 2.85301 4.26501 2.82401V2.17599C4.26501 0.975995 5.24602 0 6.45102 0H25.394C27.855 0 29.865 1.952 29.945 4.38C30.777 4.988 31.476 5.73401 32.021 6.59801L32.025 6.604C35.361 6.847 38 9.62301 38 13.001V20.999C38 24.377 35.361 27.153 32.025 27.396L32.021 27.403C31.468 28.274 30.771 29.02 29.945 29.62C29.865 32.049 27.855 34 25.394 34Z" fill="#8090A0"/>-->
    <!--    </svg>-->
    <svg
      v-if="seat_number <= 36"
      width="38"
      height="34"
      viewBox="0 0 38 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="cursor-pointer"
      style="display: flex;"
      :style="left && 'transform: rotate(180deg)'"
    >
      <path
        v-if="!isDisable(ticket)"
        fill="#50d1fa"
        d="M31.549 33.5H12.605C10.371 33.5 8.552 31.693 8.552 29.471C8.552 29.435 8.554 29.399 8.556 29.364L8.40201 29.254C7.60501 28.685 6.93101 27.972 6.40201 27.136L6.258 26.91C3.066 26.809 0.5 24.197 0.5 20.999V13.001C0.5 9.80301 3.06601 7.191 6.26001 7.09L6.39999 6.86501C6.92399 6.03601 7.59701 5.323 8.40201 4.746L8.556 4.636C8.554 4.6 8.552 4.56501 8.552 4.52901C8.552 2.30701 10.371 0.5 12.605 0.5H31.549C32.478 0.5 33.234 1.25199 33.234 2.17599V3.32401H33.681C35.787 3.32401 37.5 5.026 37.5 7.118V26.885C37.5 28.976 35.787 30.678 33.681 30.678H33.234V31.824C33.234 32.748 32.478 33.5 31.549 33.5Z"
      />
      <path
        v-if="hasSelected(ticket)"
        fill="#fafa75"
        d="M31.549 33.5H12.605C10.371 33.5 8.552 31.693 8.552 29.471C8.552 29.435 8.554 29.399 8.556 29.364L8.40201 29.254C7.60501 28.685 6.93101 27.972 6.40201 27.136L6.258 26.91C3.066 26.809 0.5 24.197 0.5 20.999V13.001C0.5 9.80301 3.06601 7.191 6.26001 7.09L6.39999 6.86501C6.92399 6.03601 7.59701 5.323 8.40201 4.746L8.556 4.636C8.554 4.6 8.552 4.56501 8.552 4.52901C8.552 2.30701 10.371 0.5 12.605 0.5H31.549C32.478 0.5 33.234 1.25199 33.234 2.17599V3.32401H33.681C35.787 3.32401 37.5 5.026 37.5 7.118V26.885C37.5 28.976 35.787 30.678 33.681 30.678H33.234V31.824C33.234 32.748 32.478 33.5 31.549 33.5Z"
      />
      <path
        v-if="!hasSelected(ticket) && seats.length > 3"
        fill="white"
        d="M31.549 33.5H12.605C10.371 33.5 8.552 31.693 8.552 29.471C8.552 29.435 8.554 29.399 8.556 29.364L8.40201 29.254C7.60501 28.685 6.93101 27.972 6.40201 27.136L6.258 26.91C3.066 26.809 0.5 24.197 0.5 20.999V13.001C0.5 9.80301 3.06601 7.191 6.26001 7.09L6.39999 6.86501C6.92399 6.03601 7.59701 5.323 8.40201 4.746L8.556 4.636C8.554 4.6 8.552 4.56501 8.552 4.52901C8.552 2.30701 10.371 0.5 12.605 0.5H31.549C32.478 0.5 33.234 1.25199 33.234 2.17599V3.32401H33.681C35.787 3.32401 37.5 5.026 37.5 7.118V26.885C37.5 28.976 35.787 30.678 33.681 30.678H33.234V31.824C33.234 32.748 32.478 33.5 31.549 33.5Z"
      />
      <path
        d="M31.549 33C32.203 33 32.734 32.474 32.734 31.824V29.471C32.734 28.821 32.203 28.295 31.549 28.295H10.234C9.58001 28.295 9.052 28.821 9.052 29.471C9.052 31.42 10.646 33 12.605 33H31.549ZM33.681 30.178C35.51 30.178 37 28.699 37 26.885V7.118C37 5.301 35.51 3.82401 33.681 3.82401H33.211V4.564C33.211 5.451 32.48 6.177 31.584 6.177H10.205C9.53102 6.177 8.93699 5.775 8.69299 5.153C7.94299 5.69 7.31401 6.355 6.82401 7.132C7.82601 7.259 8.582 8.111 8.582 9.118V24.885C8.582 25.889 7.82601 26.741 6.82401 26.868C7.31601 27.645 7.94299 28.312 8.69299 28.847C8.93699 28.227 9.53102 27.826 10.205 27.826H31.584C32.48 27.826 33.211 28.549 33.211 29.439V30.178H33.681ZM6.44901 26.413C7.36501 26.413 8.10699 25.676 8.10699 24.765V9.235C8.10699 8.325 7.36501 7.58701 6.44901 7.58701C3.43901 7.58701 1 10.01 1 13.001V20.999C1 23.99 3.43901 26.413 6.44901 26.413ZM31.549 5.705C32.203 5.705 32.734 5.17901 32.734 4.52901V2.17599C32.734 1.52599 32.203 1 31.549 1H12.605C10.646 1 9.052 2.58001 9.052 4.52901C9.052 5.17901 9.58001 5.705 10.234 5.705H31.549ZM31.549 34H12.605C10.144 34 8.13502 32.049 8.05402 29.62C7.22802 29.02 6.531 28.274 5.978 27.403L5.974 27.396C2.638 27.153 0 24.377 0 20.999V13.001C0 9.62301 2.638 6.847 5.974 6.604L5.978 6.59801C6.523 5.73401 7.22202 4.988 8.05402 4.38C8.13502 1.952 10.144 0 12.605 0H31.549C32.754 0 33.734 0.975995 33.734 2.17599V2.82401C36.092 2.85301 38 4.768 38 7.118V26.885C38 29.235 36.092 31.15 33.734 31.178V31.824C33.734 33.024 32.754 34 31.549 34Z"
        fill="#8090A0"
      />
    </svg>
    <!--    <svg style="display: flex" @click="selectSeat" width="32" height="40" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--      <path v-if="!isDisable(ticket)" fill="#50d1fa" d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z" />-->
    <!--      <path v-if="hasSelected(ticket)" fill="#fafa75" d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z" />-->
    <!--      <path v-if="!hasSelected(ticket) && seats.length > 3" fill="white" d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z" />-->
    <!--      <path d="M5.353 1C4.187 1 3.09299 1.458 2.27299 2.2888C1.45099 3.1221 1 4.2314 1 5.4148V37.6047H4.38599C4.38599 36.5598 4.789 35.5747 5.521 34.8313C6.254 34.0901 7.22599 33.6804 8.25699 33.6804H23.74C25.875 33.6804 27.613 35.4412 27.613 37.6047H31V5.4148C31 4.2339 30.547 3.1221 29.726 2.2888C28.904 1.458 27.808 1 26.644 1H5.353ZM8.25699 34.1731C6.37899 34.1731 4.87099 35.7036 4.87099 37.6069C4.87099 39.5083 6.37899 41.0413 8.25699 41.0413H23.74C25.617 41.0413 27.127 39.5083 27.127 37.6069C27.127 35.7036 25.617 34.1731 23.74 34.1731H8.25699ZM1.48399 38.0952C1.21799 38.0952 1 38.3159 1 38.5852C1 41.0205 2.953 43 5.353 43H26.644C27.804 43 28.9 42.542 29.722 41.7085C30.547 40.8755 31 39.7659 31 38.5852C31 38.3159 30.781 38.0952 30.513 38.0952H27.584C27.343 40.0537 25.691 41.5291 23.74 41.5291H8.25699C6.30799 41.5291 4.654 40.0537 4.416 38.0952H4.41399H1.48399ZM5.353 0H26.644C28.078 0 29.425 0.563 30.437 1.5854C31.445 2.6096 32 3.969 32 5.4148V37.6047V38.6047C31.994 40.0442 31.439 41.3955 30.433 42.412C29.423 43.436 28.078 44 26.644 44H5.353C2.408 44 0.009 41.5815 0 38.6047V38.5852V37.6047V5.4148C0 3.9666 0.553996 2.6069 1.562 1.5864C2.572 0.563199 3.918 0 5.353 0Z" fill="#8090A0"/>-->
    <!--    </svg>-->
  </div>
</template>
<script>
import { doOrderApi } from "@/services/railway_do_order";
export default {
  name: "Cv_ticket",
  props: {
    placess: Array,
    ticket: String,
    arr: Array,
    seat_number: Number,
    disable: Boolean,
    indexOf: Number,
    disables: Boolean,
    seats: Array,
    left: Boolean,
  },
  data() {
    return {
      yellow: "yellow",
      blue: "#0EB8EE",
      disables1: "white",
      bg_color: "",
    };
  },
  setup() {
    const { passengerss } = doOrderApi;
    return {
      passengerss,
    };
  },
  methods: {
    backround() {
      if (this.hasSelected(this.ticket)) return this.blue;
    },
    hasSelected(ticket) {
      return this.seats && this.seats.includes(ticket);
    },
    hasSeat(ticket) {
      return this.placess.includes(ticket);
    },
    isDisable(ticket) {
      if (this.seats && this.seats.length > 0) {
        if (this.placess.includes(ticket)) {
          let ticketIndex = this.placess.findIndex((it) => it === ticket);
          let isLeft = false;
          let isRight = false;
          let isCenter = false;
          for (let i = 0; i < this.seats.length; i++) {
            let seatIndex = this.placess.findIndex(
              (it) => it === this.seats[i]
            );
            if (seatIndex !== -1 && seatIndex - 1 === ticketIndex && !isLeft) {
              isLeft = true;
            }
            if (seatIndex !== -1 && seatIndex + 1 === ticketIndex && !isRight) {
              isRight = true;
            }
            if (
              seatIndex !== -1 &&
              seatIndex + 1 === ticketIndex &&
              isRight &&
              isLeft
            ) {
              isCenter = true;
            }
          }
          if (isLeft) {
            return false;
          }
          if (isRight) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      } else {
        return !this.placess.includes(ticket);
      }
    },
    selectSeat() {
      let isSeatSelected = false;
      for (let seat of this.seats) {
        isSeatSelected = seat === this.ticket;
      }
      if (
        !this.placess.includes(this.ticket) ||
        (this.isDisable(this.ticket) && !isSeatSelected)
      ) {
        return;
      }
      if (this.seats.length > 2) {
        let seatIndex = this.seats.indexOf(this.ticket);
        if (
          seatIndex !== -1 &&
          seatIndex !== 0 &&
          seatIndex !== this.seats.length - 1
        ) {
          return;
        }
      }
      this.$emit("selectBilet", this.placess.indexOf(this.ticket), this.ticket);
    },
  },
};
</script>

<style scoped>
.seat_have {
  background-color: #0eb8ee;
}
.cv_style {
  width: 37px;
  height: 19px;
}
.cv_style_h {
  width: 37px;
  height: 108px;
}
.bg-chair {
  background-color: #dbe6f3;
  cursor: pointer;
}
.success22 {
  background-color: #0eb8ee;
  color: white;
}
.success2 {
  color: #50d1fa;
}
.success222 {
  background-color: #dbe6f3;
  color: white;
}
.topk {
  height: 8px;
  width: 18px;
  background-color: #e4effc;
}
.txc {
  color: #fafa75;
}
</style>
