<template>
  <div style="position: relative" @click="selectSeat">
    <div style="position: absolute; left: 10px; top: 8px; font-size: 14px">
      <spanm style="color: #6d6d6d; cursor: pointer">{{ seat_number }}</spanm>
    </div>
    <svg
      style="display: flex"
      width="32"
      height="40"
      viewBox="0 0 32 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="cursor-pointer"
    >
      <path
        v-if="!isDisable(ticket)"
        fill="#50d1fa"
        d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z"
      />
      <path
        v-if="hasSelected(ticket)"
        fill="#fafa75"
        d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z"
      />
      <path
        v-if="!hasSelected(ticket) && seats.length > 3"
        fill="white"
        d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z"
      />
      <path
        d="M5.353 1C4.187 1 3.09299 1.458 2.27299 2.2888C1.45099 3.1221 1 4.2314 1 5.4148V37.6047H4.38599C4.38599 36.5598 4.789 35.5747 5.521 34.8313C6.254 34.0901 7.22599 33.6804 8.25699 33.6804H23.74C25.875 33.6804 27.613 35.4412 27.613 37.6047H31V5.4148C31 4.2339 30.547 3.1221 29.726 2.2888C28.904 1.458 27.808 1 26.644 1H5.353ZM8.25699 34.1731C6.37899 34.1731 4.87099 35.7036 4.87099 37.6069C4.87099 39.5083 6.37899 41.0413 8.25699 41.0413H23.74C25.617 41.0413 27.127 39.5083 27.127 37.6069C27.127 35.7036 25.617 34.1731 23.74 34.1731H8.25699ZM1.48399 38.0952C1.21799 38.0952 1 38.3159 1 38.5852C1 41.0205 2.953 43 5.353 43H26.644C27.804 43 28.9 42.542 29.722 41.7085C30.547 40.8755 31 39.7659 31 38.5852C31 38.3159 30.781 38.0952 30.513 38.0952H27.584C27.343 40.0537 25.691 41.5291 23.74 41.5291H8.25699C6.30799 41.5291 4.654 40.0537 4.416 38.0952H4.41399H1.48399ZM5.353 0H26.644C28.078 0 29.425 0.563 30.437 1.5854C31.445 2.6096 32 3.969 32 5.4148V37.6047V38.6047C31.994 40.0442 31.439 41.3955 30.433 42.412C29.423 43.436 28.078 44 26.644 44H5.353C2.408 44 0.009 41.5815 0 38.6047V38.5852V37.6047V5.4148C0 3.9666 0.553996 2.6069 1.562 1.5864C2.572 0.563199 3.918 0 5.353 0Z"
        fill="#8090A0"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "Cv_ticket",

  props: {
    placess: Array,
    ticket: String,
    arr: Array,
    seat_number: Number,
    disable: Boolean,
    indexOf: Number,
    disables: Boolean,
    seats: Array,
  },

  methods: {
    hasSelected(ticket) {
      return this.seats && this.seats.includes(ticket);
    },

    isDisable(ticket) {
      if (this.seats && this.seats.length > 0) {
        if (this.placess.includes(ticket)) {
          let ticketIndex = this.placess.findIndex((item) => item === ticket);
          let isLeft = false;
          let isRight = false;
          let isCenter = false;
          for (let i = 0; i < this.seats.length; i++) {
            let seatIndex = this.placess.findIndex(
              (item) => item === this.seats[i]
            );
            if (seatIndex !== -1 && seatIndex - 1 === ticketIndex && !isLeft) {
              isLeft = true;
            }
            if (seatIndex !== -1 && seatIndex + 1 === ticketIndex && !isRight) {
              isRight = true;
            }
            if (
              seatIndex !== -1 &&
              seatIndex + 1 === ticketIndex &&
              isRight &&
              isLeft
            ) {
              isCenter = true;
            }
          }
          if (isLeft) {
            return false;
          }
          if (isRight) {
            return false;
          }
          if (isCenter) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      } else {
        return !this.placess.includes(ticket);
      }
    },
    selectSeat() {
      let isSeatSelected = false;
      for (let seat of this.seats) {
        isSeatSelected = seat === this.ticket;
      }
      if (
        !this.placess.includes(this.ticket) ||
        (this.isDisable(this.ticket) && !isSeatSelected)
      ) {
        return;
      }
      if (this.seats.length > 2) {
        let seatIndex = this.seats.indexOf(this.ticket);
        if (
          seatIndex !== -1 &&
          seatIndex !== 0 &&
          seatIndex !== this.seats.length - 1
        ) {
          return;
        }
      }
      this.$emit("selectBilet", this.placess.indexOf(this.ticket), this.ticket);
    },
  },

  mounted() {
    if (this.seats.length > 0)
      for (let i = 0; i < this.seats.length; i++) this.seats.pop();
  },
};
</script>

<style scoped>
.seat_have {
  background-color: #0eb8ee;
}
.cv_style {
  width: 37px;
  height: 19px;
}
.cv_style_h {
  width: 37px;
  height: 108px;
}
.bg-chair {
  background-color: #dbe6f3;
  cursor: pointer;
}
.success22 {
  background-color: #0eb8ee;
  color: white;
}
.success2 {
  color: #50d1fa;
}
.success222 {
  background-color: #dbe6f3;
  color: white;
}
.topk {
  height: 8px;
  width: 18px;
  background-color: #e4effc;
}
.txc {
  color: #fafa75;
}
</style>
