<template>
  <div style="position: relative" @click="selectSeat">
    <div
      style="position: absolute; left: 12px; top: 6px; font-size: 14px; z-index: 99"
    >
      <div style="color: #6d6d6d; cursor: pointer">{{ seat_number }}</div>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      style="display: flex"
      class="cursor-pointer"
      :style="[
        left && 'transform: rotate(180deg)',
        straight && 'transform: rotate(-90deg)',
      ]"
    >
      <path
        v-if="!isDisable(ticket)"
        fill="#50d1fa"
        d="M23.5 31.5001H9.5H9V31.3661C8.853 31.4511 8.681 31.5001 8.5 31.5001H5.5C2.742 31.5001 0.5 29.2561 0.5 26.4989V5.4989C0.5 2.7425 2.742 0.5 5.5 0.5H8.5C8.681 0.5 8.853 0.548902 9 0.634102V0.5H9.5H23.5C24.326 0.5 25 1.1735 25 2.0014V4.4993H30C30.826 4.4993 31.5 5.17279 31.5 6.00069V25.9993C31.5 26.8272 30.826 27.5007 30 27.5007H25V30.0011C25 30.8271 24.326 31.5001 23.5 31.5001Z"
      />
      <path
        v-if="hasSelected(ticket)"
        fill="#fafa75"
        d="M23.5 31.5001H9.5H9V31.3661C8.853 31.4511 8.681 31.5001 8.5 31.5001H5.5C2.742 31.5001 0.5 29.2561 0.5 26.4989V5.4989C0.5 2.7425 2.742 0.5 5.5 0.5H8.5C8.681 0.5 8.853 0.548902 9 0.634102V0.5H9.5H23.5C24.326 0.5 25 1.1735 25 2.0014V4.4993H30C30.826 4.4993 31.5 5.17279 31.5 6.00069V25.9993C31.5 26.8272 30.826 27.5007 30 27.5007H25V30.0011C25 30.8271 24.326 31.5001 23.5 31.5001Z"
      />
      <path
        v-if="!hasSelected(ticket) && seats.length > 3"
        fill="white"
        d="M23.5 31.5001H9.5H9V31.3661C8.853 31.4511 8.681 31.5001 8.5 31.5001H5.5C2.742 31.5001 0.5 29.2561 0.5 26.4989V5.4989C0.5 2.7425 2.742 0.5 5.5 0.5H8.5C8.681 0.5 8.853 0.548902 9 0.634102V0.5H9.5H23.5C24.326 0.5 25 1.1735 25 2.0014V4.4993H30C30.826 4.4993 31.5 5.17279 31.5 6.00069V25.9993C31.5 26.8272 30.826 27.5007 30 27.5007H25V30.0011C25 30.8271 24.326 31.5001 23.5 31.5001Z"
      />
      <path
        d="M23.5 31.0001C24.051 31.0001 24.5 30.5521 24.5 30.0011V27.5004H9.5V31.0001H23.5ZM8.5 31.0001C8.777 31.0001 9 30.7761 9 30.5001V1.4996C9 1.2237 8.777 1 8.5 1H5.5C3.015 1 1 3.0138 1 5.4989V26.4989C1 28.9841 3.015 31.0001 5.5 31.0001H8.5ZM30 27.0007C30.552 27.0007 31 26.5511 31 25.9993V6.00069C31 5.44889 30.552 4.9993 30 4.9993H9.5V27.0007H30ZM24.5 4.4996V2.0014C24.5 1.4497 24.051 1 23.5 1H9.5V4.4996H24.498H24.5ZM23.5 32.0001H9.5H8.5H5.5C2.467 32.0001 0 29.5321 0 26.4989V5.4989C0 2.4668 2.467 0 5.5 0H8.5H9.5H23.5C24.603 0 25.5 0.897796 25.5 2.0014V3.9993H30C31.103 3.9993 32 4.89709 32 6.00069V25.9993C32 27.1029 31.103 28.0007 30 28.0007H25.5V30.0011C25.5 31.1031 24.603 32.0001 23.5 32.0001Z"
        fill="#8090A0"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "Cv_ticket",

  props: {
    placess: Array,
    ticket: String,
    arr: Array,
    seat_number: Number,
    disable: Boolean,
    indexOf: Number,
    disables: Boolean,
    seats: Array,
    left: Boolean,
    straight: Boolean,
  },

  methods: {
    hasSelected(ticket) {
      return this.seats && this.seats.includes(ticket);
    },

    isDisable(ticket) {
      if (this.seats && this.seats.length > 0) {
        if (this.placess.includes(ticket)) {
          let ticketIndex = this.placess.findIndex((item) => item === ticket);
          let isLeft = false;
          let isRight = false;
          let isCenter = false;
          for (let i = 0; i < this.seats.length; i++) {
            let seatIndex = this.placess.findIndex(
              (item) => item === this.seats[i]
            );
            if (seatIndex !== -1 && seatIndex - 1 === ticketIndex && !isLeft) {
              isLeft = true;
            }
            if (seatIndex !== -1 && seatIndex + 1 === ticketIndex && !isRight) {
              isRight = true;
            }
            if (
              seatIndex !== -1 &&
              seatIndex + 1 === ticketIndex &&
              isRight &&
              isLeft
            ) {
              isCenter = true;
            }
          }
          if (isLeft) {
            return false;
          }
          if (isRight) {
            return false;
          }
          if (isCenter) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      } else {
        return !this.placess.includes(ticket);
      }
    },
    selectSeat() {
      let isSeatSelected = false;
      for (let seat of this.seats) {
        isSeatSelected = seat === this.ticket;
      }
      if (
        !this.placess.includes(this.ticket) ||
        (this.isDisable(this.ticket) && !isSeatSelected)
      ) {
        return;
      }
      if (this.seats.length > 2) {
        let seatIndex = this.seats.indexOf(this.ticket);
        if (
          seatIndex !== -1 &&
          seatIndex !== 0 &&
          seatIndex !== this.seats.length - 1
        ) {
          return;
        }
      }
      this.$emit("selectBilet", this.placess.indexOf(this.ticket), this.ticket);
    },
  },

  mounted() {
    if (this.seats.length > 0)
      for (let i = 0; i < this.seats.length; i++) this.seats.pop();
  },
};
</script>

<style scoped>
.seat_have {
  background-color: #0eb8ee;
}
.cv_style {
  width: 37px;
  height: 19px;
}
.cv_style_h {
  width: 37px;
  height: 108px;
}
.bg-chair {
  background-color: #dbe6f3;
  cursor: pointer;
}
.success22 {
  background-color: #0eb8ee;
  color: white;
}
.success2 {
  color: #50d1fa;
}
.success222 {
  background-color: #dbe6f3;
  color: white;
}
.topk {
  height: 8px;
  width: 18px;
  background-color: #e4effc;
}
.txc {
  color: #fafa75;
}
</style>
