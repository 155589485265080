<template>
  <div style="position: relative" @click="selectSeat">
    <div style="position: absolute; left: 10px; top: 8px; font-size: 14px">
      <spanm style="color: #6d6d6d; cursor: pointer">{{ seat_number }}</spanm>
    </div>
    <svg
      v-if="seat_number <= 36"
      style="display: flex"
      width="32"
      height="40"
      viewBox="0 0 32 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="cursor-pointer"
    >
      <path
        v-if="!isDisable(ticket)"
        fill="#50d1fa"
        d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z"
      />
      <path
        v-if="hasSelected(ticket)"
        fill="#fafa75"
        d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z"
      />
      <path
        v-if="!hasSelected(ticket) && seats.length > 3"
        fill="white"
        d="M26.7456 94.5H5.45456C4.15356 94.5 2.93356 93.9895 2.01956 93.0625C1.10456 92.136 0.601562 90.9011 0.601562 89.5852V6.3953V5.8953H0.724564C0.645564 5.7529 0.601562 5.5891 0.601562 5.4148C0.601562 2.70483 2.77856 0.5 5.45456 0.5H26.7456C28.0446 0.5 29.2636 1.01147 30.1796 1.94019C31.0956 2.86646 31.6015 4.1006 31.6015 5.4148C31.6015 5.5891 31.5565 5.7529 31.4765 5.8953H31.6015V6.3953V89.5852C31.6015 90.8989 31.0975 92.1338 30.1835 93.062C29.2655 93.9897 28.0446 94.5 26.7456 94.5Z"
      />
      <path
        d="M5.353 1C4.187 1 3.09299 1.458 2.27299 2.2888C1.45099 3.1221 1 4.2314 1 5.4148V37.6047H4.38599C4.38599 36.5598 4.789 35.5747 5.521 34.8313C6.254 34.0901 7.22599 33.6804 8.25699 33.6804H23.74C25.875 33.6804 27.613 35.4412 27.613 37.6047H31V5.4148C31 4.2339 30.547 3.1221 29.726 2.2888C28.904 1.458 27.808 1 26.644 1H5.353ZM8.25699 34.1731C6.37899 34.1731 4.87099 35.7036 4.87099 37.6069C4.87099 39.5083 6.37899 41.0413 8.25699 41.0413H23.74C25.617 41.0413 27.127 39.5083 27.127 37.6069C27.127 35.7036 25.617 34.1731 23.74 34.1731H8.25699ZM1.48399 38.0952C1.21799 38.0952 1 38.3159 1 38.5852C1 41.0205 2.953 43 5.353 43H26.644C27.804 43 28.9 42.542 29.722 41.7085C30.547 40.8755 31 39.7659 31 38.5852C31 38.3159 30.781 38.0952 30.513 38.0952H27.584C27.343 40.0537 25.691 41.5291 23.74 41.5291H8.25699C6.30799 41.5291 4.654 40.0537 4.416 38.0952H4.41399H1.48399ZM5.353 0H26.644C28.078 0 29.425 0.563 30.437 1.5854C31.445 2.6096 32 3.969 32 5.4148V37.6047V38.6047C31.994 40.0442 31.439 41.3955 30.433 42.412C29.423 43.436 28.078 44 26.644 44H5.353C2.408 44 0.009 41.5815 0 38.6047V38.5852V37.6047V5.4148C0 3.9666 0.553996 2.6069 1.562 1.5864C2.572 0.563199 3.918 0 5.353 0Z"
        fill="#8090A0"
      />
    </svg>
    <svg
      v-else
      @click="selectSeat"
      width="38"
      height="32"
      viewBox="0 0 44 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1270_14988)">
        <path
          v-if="!isDisable(ticket)"
          fill-rule="evenodd"
          fill="#50d1fa"
          d="M43.5 26.644L43.5 5.353C43.5 4.052 42.9895 2.832 42.0625 1.918C41.136 1.004 39.9011 0.5 38.5852 0.5L6.39528 0.5L5.89528 0.5L5.89528 0.623016C5.75298 0.545016 5.58908 0.5 5.41478 0.5C2.70484 0.5 0.5 2.677 0.5 5.353L0.5 26.644C0.5 27.943 1.01148 29.162 1.94019 30.078C2.86646 30.994 4.10058 31.5 5.41478 31.5C5.58908 31.5 5.75298 31.455 5.89528 31.375L5.89528 31.5L6.39528 31.5L38.5852 31.5C39.8989 31.5 41.1338 30.996 42.062 30.082C42.9898 29.164 43.5 27.943 43.5 26.644Z"
        />
        <path
          v-if="hasSelected(ticket)"
          fill="#fafa75"
          d="M43.5 26.644L43.5 5.353C43.5 4.052 42.9895 2.832 42.0625 1.918C41.136 1.004 39.9011 0.5 38.5852 0.5L6.39528 0.5L5.89528 0.5L5.89528 0.623016C5.75298 0.545016 5.58908 0.5 5.41478 0.5C2.70484 0.5 0.5 2.677 0.5 5.353L0.5 26.644C0.5 27.943 1.01148 29.162 1.94019 30.078C2.86646 30.994 4.10058 31.5 5.41478 31.5C5.58908 31.5 5.75298 31.455 5.89528 31.375L5.89528 31.5L6.39528 31.5L38.5852 31.5C39.8989 31.5 41.1338 30.996 42.062 30.082C42.9898 29.164 43.5 27.943 43.5 26.644Z"
        />
        <path
          v-if="!hasSelected(ticket) && seats.length > 3"
          fill="white"
          d="M43.5 26.644L43.5 5.353C43.5 4.052 42.9895 2.832 42.0625 1.918C41.136 1.004 39.9011 0.5 38.5852 0.5L6.39528 0.5L5.89528 0.5L5.89528 0.623016C5.75298 0.545016 5.58908 0.5 5.41478 0.5C2.70484 0.5 0.5 2.677 0.5 5.353L0.5 26.644C0.5 27.943 1.01148 29.162 1.94019 30.078C2.86646 30.994 4.10058 31.5 5.41478 31.5C5.58908 31.5 5.75298 31.455 5.89528 31.375L5.89528 31.5L6.39528 31.5L38.5852 31.5C39.8989 31.5 41.1338 30.996 42.062 30.082C42.9898 29.164 43.5 27.943 43.5 26.644Z"
        />
        <path
          d="M43 26.644C43 27.808 42.542 28.904 41.7112 29.726C40.878 30.547 39.7661 31 38.5852 31L6.39528 31L6.39528 27.613C8.55888 27.613 10.3196 25.875 10.3196 23.74L10.3196 8.258C10.3196 7.226 9.90988 6.254 9.16868 5.521C8.42528 4.789 7.44018 4.38602 6.39528 4.38602L6.39528 1L38.5852 1C39.7686 1 40.878 1.45101 41.7112 2.27301C42.542 3.09401 43 4.187 43 5.353L43 26.644ZM9.82688 23.74C9.82688 25.617 8.29638 27.127 6.39308 27.127C4.49168 27.127 2.95874 25.617 2.95874 23.74L2.95874 8.258C2.95874 6.379 4.49168 4.871 6.39308 4.871C8.29638 4.871 9.82688 6.379 9.82688 8.258L9.82688 23.74ZM5.90478 30.513C5.90478 30.781 5.68408 31 5.41478 31C4.23418 31 3.12452 30.547 2.29151 29.722C1.45826 28.9 1 27.804 1 26.644L1 5.353C1 2.953 2.9795 1 5.41478 1C5.68408 1 5.90478 1.21801 5.90478 1.48401L5.90478 4.414L5.90478 4.41602C3.94629 4.65402 2.47095 6.308 2.47095 8.258L2.47095 23.74C2.47095 25.691 3.94629 27.344 5.90478 27.584L5.90478 30.513ZM44 26.644L44 5.353C44 3.918 43.4368 2.57201 42.4138 1.56201C41.3931 0.554013 40.0335 4.77395e-07 38.5852 4.60124e-07L6.39528 7.62629e-08L5.39528 6.43381e-08C2.41846 0.01 -2.87151e-08 2.408 -6.38339e-08 5.353L-3.17727e-07 26.644C-3.34827e-07 28.078 0.56446 29.424 1.58911 30.433C2.6045 31.439 3.95581 31.994 5.39528 32L5.41478 32L6.39528 32L38.5852 32C40.031 32 41.3904 31.445 42.4129 30.439C43.437 29.426 44 28.078 44 26.644Z"
          fill="#8090A0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1270_14988">
          <rect
            width="32"
            height="44"
            fill="white"
            transform="matrix(-1.19249e-08 1 1 1.19249e-08 0 0)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
<script>
export default {
  name: "Cv_ticket",
  props: {
    placess: Array,
    ticket: String,
    arr: Array,
    seat_number: Number,
    disable: Boolean,
    indexOf: Number,
    disables: Boolean,
    seats: Array,
  },
  data() {
    return {
      yellow: "yellow",
      blue: "#0EB8EE",
      disables1: "white",
      bg_color: "",
    };
  },
  methods: {
    backround() {
      if (this.hasSelected(this.ticket)) return this.blue;
    },
    hasSelected(ticket) {
      return this.seats && this.seats.includes(ticket);
    },
    hasSeat(ticket) {
      return this.placess.includes(ticket);
    },
    isDisable(ticket) {
      if (this.seats && this.seats.length > 0) {
        if (this.placess.includes(ticket)) {
          let ticketIndex = this.placess.findIndex((it) => it === ticket);
          let isLeft = false;
          let isRight = false;
          let isCenter = false;
          for (let i = 0; i < this.seats.length; i++) {
            let seatIndex = this.placess.findIndex(
              (it) => it === this.seats[i]
            );
            if (seatIndex !== -1 && seatIndex - 1 === ticketIndex && !isLeft) {
              isLeft = true;
            }
            if (seatIndex !== -1 && seatIndex + 1 === ticketIndex && !isRight) {
              isRight = true;
            }
            if (
              seatIndex !== -1 &&
              seatIndex + 1 === ticketIndex &&
              isRight &&
              isLeft
            ) {
              isCenter = true;
            }
          }
          if (isLeft) {
            return false;
          }
          if (isRight) {
            return false;
          }
          if (isCenter) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      } else {
        return !this.placess.includes(ticket);
      }
    },
    selectSeat() {
      let isSeatSelected = false;
      for (let seat of this.seats) {
        isSeatSelected = seat === this.ticket;
      }
      if (
        !this.placess.includes(this.ticket) ||
        (this.isDisable(this.ticket) && !isSeatSelected)
      ) {
        return;
      }
      if (this.seats.length > 2) {
        let seatIndex = this.seats.indexOf(this.ticket);
        if (
          seatIndex !== -1 &&
          seatIndex !== 0 &&
          seatIndex !== this.seats.length - 1
        ) {
          return;
        }
      }
      this.$emit("selectBilet", this.placess.indexOf(this.ticket), this.ticket);
    },
  },
};
</script>

<style scoped>
.seat_have {
  background-color: #0eb8ee;
}
.cv_style {
  width: 37px;
  height: 19px;
}
.cv_style_h {
  width: 37px;
  height: 108px;
}
.bg-chair {
  background-color: #dbe6f3;
  cursor: pointer;
}
.success22 {
  background-color: #0eb8ee;
  color: white;
}
.success2 {
  color: #50d1fa;
}
.success222 {
  background-color: #dbe6f3;
  color: white;
}
.topk {
  height: 8px;
  width: 18px;
  background-color: #e4effc;
}
.txc {
  color: #fafa75;
}
</style>
